import { Link } from 'gatsby';
import React from 'react';

const FirstContent = ({ features, rtl }) => {
  return (
    <div className="content frs-content"  id="about" data-scroll-index="2">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/about/IPPIS LOAN.png" alt="" className='' />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
            <h4>{"IPPIS Loan"}</h4>
            <div className="section-head mb-10 style-4">
              <h2>
                {"Prompt Cash Loans for"} <span>{"FG Civil Servants"}</span>
              </h2>
            </div>
            <p>
              {`Put your financial worries to rest with our fast and easy IPPIS loans. Pay your rent, school fees, medical expenses and other financial needs at a go. `}
            </p>

            <a
              href="https://mtloans.ng/login?r=DM1"
              className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-20"
            >
              <span>{"Apply for IPPIS Loan"}</span>
            </a>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_lines.png" alt="" className="lines" />
      <img src="/assets/img/about/about_s4_bubble.png" alt="" className="bubble" />
    </div>
  )
}

export default FirstContent;