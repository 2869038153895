import { Link } from 'gatsby';
import React from 'react';

const FouthContent = ({ accordions, rtl }) => {
  return (
    <div className="content sec-content">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 order-2 order-lg-0">
            <div className="info">
            <h4>{  'SME Loan:' }</h4>
            <div className="section-head mb-30 style-4">
            <h2>{"Beta Business,"} <span>{  'Beta Moni' }</span></h2>              
            </div>
            <p>
            Your business deserves the right amount of funds and that’s where we come in. Get easy access to funds of up to N100 million to take your business to the next level.
            </p>
            
            <div className="d-flex align-items-center mt-10">
            <a href="https://mtloans.ng/login?r=DM1" className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-20">
              <span>{ 'Apply for SME Loan' }</span>
            </a>
            </div>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="img mb-30 mb-lg-0 ">
              <img src="/assets/img/about/SME LOAN.png" alt="" className=''  />
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_bubble2.png" alt="" className="bubble2" />
    </div>
  )
}

export default FouthContent;