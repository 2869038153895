import React from 'react';
import appData from 'data/appData.json';

const BuyNow = () => {
  return (
    <section className="box-gr mt-20">
      <div className=" container-xxl box">
        <div className="row">
          <div className="col-lg-7 valign">
            <div className="cont mb-5 mb-lg-0">
              <h2 className="mb-20 lh-3 fs-1 text-capitalize">Ready To Download</h2>
              <p className="color-777 lh-6 fs-14px">Your can do more on our mobile applications. Download from App Store or Google Play.</p>
              <div className="d-flex align-items-center justify-content-center mt-5">
            <a href="https://www.apple.com/app-store" rel="noreferrer" className="btn rounded-pill bg-blue4 fw-bold text-white me-4" target="_blank">
              <small> <i className="fab fa-apple me-2 pe-2 border-end"></i> { 'Download On App Store' }</small>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mutualtrustmfb.mutualtrustmfbmobile" rel="noreferrer" className="btn rounded-pill hover-blue4 fw-bold border-blue4" target="_blank">
              <small> <i className="fab fa-google-play me-2 pe-2 border-end"></i> {  'Download On Google Play' }</small>
            </a>
          </div>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-1">
            <div className="img">
              <img src="/assets/img/about/iphone_mockup.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuyNow