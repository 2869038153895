import React, { useEffect, useState } from 'react';
import testimonials from 'data/Preview/testimonials.json';
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

const Testimonials = () => {
  //const testimonialsData = rtl ? testimonialsRTL : testimonials;
  const [loading, setLoading] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get("https://blog.mutualtrustmfb.com/review/")
        .then((response) => {
          const res = setTestimonialsData(response.data);
        
        });

      setLoading(false);
    })();
  }, []);



  return (
    <section className="testim-curv section-padding">
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="sec-head text-center mb-80">
              <h2 className="color-000 text-capitalize">Clients Reviews</h2>
              <p>Mutual Trust MFBank: Trusted by thousands worldwide, endorsed by industry leaders.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="testim-grida">
              <div className="swiper-container">
                <Swiper
                  className="swiper-wrapper" 
                  slidesPerView={1} 
                  spaceBetween={50} 
                  centeredSlides={true} 
                  loop={true}
                  autoplay={true}
                  breakpoints={{
                    1920: {
                      slidesPerView: 3,
                    },
                    1028: {
                      slidesPerView: 2,
                    },
                    480: {
                      slidesPerView: 1,
                    },
                    375: {
                      slidesPerView: 1,
                     
                    }
                  }}
                >
                  {
                  testimonialsData && testimonialsData?.map((testimonial, i) => (
                      <SwiperSlide key={i}>
                        <div className="item">
                          <div className="icon-img icon-40">
                            <img src="/landing-preview/img/quotes.png" alt="" />
                          </div>
                          <div className="text mb-30">
                            <span className="rate-star fz-10 mb-10">
                              { Array(testimonial.stars).fill().map((_,s) => <i className="fas fa-star" key={s}></i>) }
                            </span>
                            <p>
                            { testimonial.review }
                            </p>
                          </div>
                          <div className="cont d-flex">
                            <div className="img-flex valign">
                              <div className="img">
                                <img src="/landing-preview/img/envato.png" alt=""
                                  className="circle-img" />
                              </div>
                            </div>
                            <div className="info valign ms-4">
                              <div>
                                <h6>{ testimonial.name }</h6>
                                <small>{ testimonial.position }</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials