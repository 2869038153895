import React from "react";
import aboutData from "data/App/about.json";
import aboutData2 from "data/Saas/about.json";
import aboutDataRTL from "data/App/about-rtl.json";
import FirstContent from "./FirstContent";
import SecondContent from "./SecondContent";
import ThirdContent from "./ThirdContent";
import FouthContent from "./FouthContent";
import FiftContent from "./FiftContent";
import SixContent from "./SixContent";
import scrollTo from "gatsby-plugin-smoothscroll";

const About = ({ noFirstContent, noIntegration, noWave, rtl }) => {
  const data = rtl ? aboutDataRTL : aboutData;
  const data1 = rtl ? aboutDataRTL : aboutData2;

  return (
    <>
      <div className="container">
        <div className="section-head text-center mb-0 style-4 pt-40">
          <h2 className="mb-20">
            {"Excellent financial products to "}{" "}
            <span>{"help you with your financial needs"}</span>{" "}
          </h2>
        </div>
      </div>

      <div className="container faq-tabs style-5 is-sticky">
        <div className="section-head text-center mb-40 style-4 pt-40"></div>
        <div className=" ">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ margin: 20, background: "#eaf4f9", borderRadius: 20, Display: 'flex',
              flexWrap:'wrap' }}
          >
            <div className="p-2">
              <a
                style={{ padding: 5 }}
                href="#IPPIS"
                onClick={() => scrollTo("#IPPIS")}
              >
                IPPIS Loan
              </a>
            </div>
            <div className="p-2">
              <a
                style={{ padding: 5 }}
                href="#one"
                onClick={() => scrollTo("#one")}
              >
                MTGreen
              </a>
            </div>
            <div className="p-2">
              <a
                style={{ padding: 5 }}
                href="#two"
                onClick={() => scrollTo("#two")}
              >
                MTloan
              </a>
            </div>
            <div>
              <a
                style={{ padding: 5 }}
                href="#three"
                onClick={() => scrollTo("#three")}
              >
                SME Loan
              </a>
            </div>
            <div className="p-2">
              <a
                style={{ padding: 5 }}
                href="#four"
                onClick={() => scrollTo("#four")}
              >
                Car4Cash
              </a>
            </div>
            <div className="p-2">
              <a
                style={{ padding: 5 }}
                href="#five"
                onClick={() => scrollTo("#five")}
              >
                Investment
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className={`about ${noWave ? "" : "section-padding"} style-4`}>
        <div id="IPPIS">
          <FirstContent features={data.features} />
        </div>
        <div id="one">
          <SecondContent accordions={data.accordions} />
        </div>
        <div id="two">
          <ThirdContent features={data.thirdFeatures} list={data1.list} />
        </div>
        <div id="three">
          <FouthContent accordions={data.accordions} />
        </div>
        <div id="four">
          <FiftContent features={data.thirdFeatures} />
        </div>
        <div id="five">
          <SixContent accordions={data.accordions} />
        </div>
      </section>
    </>
  );
};

export default About;
