import React from 'react';
//import portfolioData from 'data/Preview/portfolio.json';
import portfolioData from 'data/startup/services.json';
import { Link } from 'gatsby';

const Portfolio = () => {
  return (
    <section className="portfolio-blocks section-padding bg-gray">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="sec-head text-center mb-80">
              <h3 className="num"><span className="gr">100%<span className="thin">+</span></span></h3>
              <h2>Discover our top services</h2>
              <p>We offer a range of financial services tailored to meet your personal and business needs. Here’s a look at our top services</p>
            </div>
          </div>
          <div className="col-12">
            <div className="main-marq">
              <div className="slide-har st1">
                <div className="box service-card">
                  {
                    portfolioData.map((service, i) => (
                      <div className="item" key={i}>
                        <Link to="/" className="service-card style-6">
                    <div className="icon">
                      <img src={service.img} style={{ height: 40, width: 40}} alt="" />
                    </div>
                    <div className="info">
                      <h5>{ service.info }</h5>
                      <div className="text m-10">
                        { service.text }
                      </div>
                    </div>
                  </Link>
                      </div>
                    ))
                  }
                </div>
                <div className="box service-card">
                {
                    portfolioData.map((service, i) => (
                      <div className="item" key={i}>
                        <Link to="/" className="service-card style-6">
                    <div className="icon">
                    <img src={service.img} style={{ height: 40, width: 40}} alt="" />
                    </div>
                    <div className="info">
                      <h5>{ service.info }</h5>
                      <div className="text">
                        { service.text }
                      </div>
                    </div>
                  </Link>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="slide-har st2 service-card">
                <div className="box service-card">
                {
                    portfolioData.map((service, i) => (
                      <div className="item" key={i}>
                        <Link to="/page-services-5" className="service-card style-6">
                    <div className="icon">
                    <img src={service.img} style={{ height: 40, width: 40}} alt="" />
                    </div>
                    <div className="info">
                      <h5>{ service.info }</h5>
                      <div className="text">
                        { service.text }
                      </div>
                    </div>
                  </Link>
                      </div>
                    ))
                  }
                </div>
                <div className="box">
                {
                    portfolioData.map((service, i) => (
                      <div className="item" key={i}>
                        <Link to="/" className="service-card style-6">
                    <div className="icon">
                    <img src={service.img} style={{ height: 40, width: 40}} alt="" />
                    </div>
                    <div className="info">
                      <h5>{ service.info }</h5>
                      <div className="text">
                        { service.text }
                      </div>
                    </div>
                  </Link>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio