import React from 'react';
import { Link } from 'gatsby';

const ThirdContent = ({ features, rtl, list }) => {
  return (
    <div className="content trd-content">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/about/MTLOANS.png" alt="" className='' />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
            <h4>{  'MTloan: ' }</h4>
            <div className="section-head mb-30 style-4">
            <h2>{"Get your loan approved in "} <span>{  '10 minutes or less!' }</span></h2>
              
            </div>            
            <p>
              {`Enjoy quick access of up to N1 million naira in just 10 minutes or less! Settle your financial needs with no hassle. No collateral is required. `}
            </p>
            <ul className="list-icon">
              {list.map((item, index) => (
                <li key={index}>
                  <span className="icon">
                    <i className={item.icon}></i>
                  </span>
                  <h6>{item.title}</h6>
                </li>
              ))}
            </ul>
            <a
              href="https://mtloans.ng/login?r=DM1"
              className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-20"
            >
              <span>{"Apply for MTloans"}</span>
            </a>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_bubble.png" alt="" className="bubble" />
    </div>
  )
}

export default ThirdContent