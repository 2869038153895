import React, { useEffect, useRef } from "react";
//= Layout
//= Components
import Header from "components/Preview/Header";
import Features from "components/Preview/Features";
import BuyNow from "components/Preview/BuyNow";
import Portfolio from "components/Preview/Portfolio";
import BestFeatures from "components/Preview/BestFeatures";
import AllFeatures from "components/Preview/AllFeatures";
import Testimonials from "components/Preview/Testimonials";
import Footer from "components/Digital/Footer";
//= Scripts
import { fixPreviewStylesheetOrder } from "common/fixStylesheetsOrder";
import About from "components/App/About";
import FAQ from "components/App/FAQ";
import BoeiChat from "components/BoeiChat";
import MainLayout from "layouts/Main";
import Navbar from "components/Navbars/ITCreativeNav";

const LandingPreview = () => {
  const navbarRef = useRef(null);
  useEffect(() => {
    document.body.classList.add("index-main");
    return () => document.body.classList.remove("index-main");
  }, []);

  useEffect(() => {
    fixPreviewStylesheetOrder();
  }, []);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} />
      <Header />     
      <Features />
      <About />
      <Portfolio />
      <BestFeatures />
      <Testimonials />
      <AllFeatures />
      <FAQ />
      <BuyNow />
      <Footer />
      <BoeiChat />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>MutualTrust MFBank</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
      <link rel="stylesheet" href="/landing-preview/css/preview-style.css" />
    </>
  );
};

export default LandingPreview;
