import React from 'react';
import { Link } from 'gatsby';

const FiftContent = ({ features, rtl }) => {
  return (
    <div className="content trd-content">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/about/CAR4CASH.png" alt="" className='' />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
            <h4>{  'Car4Cash: ' }</h4>
            <div className="section-head mb-10 style-4">
            <h2>{"Your Car can be "} <span>{  'much more!' }</span></h2>              
            </div>
            <p>{'Do you have a car and you need quick funds? Explore the value of your and get the funds that you need.' }</p>
            
           
            <Link to="#" className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4 mt-20" target="_blank">
                    <small> Apply for Car4Cash<i className="fas fa-long-arrow-alt-right"></i> </small>
                  </Link>
          
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_bubble.png" alt="" className="bubble" />
    </div>
  )
}

export default FiftContent