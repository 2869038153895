import { Link } from 'gatsby';
import React from 'react';

const SecondContent = ({ accordions, rtl }) => {
  return (
    <div className="content sec-content">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 order-2 order-lg-0">
            <div className="info">
            <h4>{  'MTGreen: ' }</h4>
            <div className="section-head mb-30 style-4">
            <h2>{"Say goodbye to high costs of fuel and"} <span>{  'electricity today' }</span></h2>
              
            </div>
            <p>
              {
                "Enjoy uniterrupted power supply to your homes, shops, and offices. Get a quality and affordable solar energy system with flexible repayments ranging from 1 to 12 months"
              }
            </p>
            
            <a
              href="https://mtloans.ng/login?r=DM1"
              className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4 mt-20"
              target="_blank"
            >
              <small>
                {" "}
                Apply for MTGreen<i className="fas fa-long-arrow-alt-right"></i>{" "}
              </small>
            </a>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/about/MTGREEN.png" alt="" className='' />
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_bubble2.png" alt="" className="bubble2" />
    </div>
  )
}

export default SecondContent