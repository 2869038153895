import { Link } from 'gatsby';
import React from 'react';

const SixContent = ({ accordions, rtl }) => {
  return (
    <div className="content sec-content">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 order-2 order-lg-0">
            <div className="info">
            <h4>{  'Investment:  ' }</h4>
            <div className="section-head mb-10 style-4">
            <h2>{"Grow your funds  "} <span>{  'with us' }</span></h2>              
            </div>
            
            <p>{'Grow your wealth with us and Enjoy guaranteed Return on investments with us. Security guaranteed with easy liquidation' }</p>
            
          
            <Link to="#" className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4 mt-20" target="_blank">
                    <small> Invest With Us<i className="fas fa-long-arrow-alt-right"></i> </small>
                  </Link>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/about/Pictureh1.jpg" alt="" className='bg-img1' />
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_bubble2.png" alt="" className="bubble2" />
    </div>
  )
}

export default SixContent